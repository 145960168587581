<template>
  <div class="bg-white p-4 flex flex-col items-start rounded-tl-lg rounded-br-lg">
    <p class="font-bold mb-0">{{redemption.awardName}}</p>
    <p class="mb-0" v-if="!isEditable">{{redemption.status}}</p>
    <select class="bg-yellow bg-opacity-20" v-else v-model="newStatus">
      <option disabled value>{{redemption.status}}</option>
      <option v-if="redemption.status != 'En validación'">En validación</option>
      <option v-if="redemption.status != 'Preparando despacho'">Preparando despacho</option>
      <option v-if="redemption.status != 'Despachando'">Despachado</option>
      <option v-if="redemption.status != 'Entregado'">Entregado</option>
    </select>
    <p class="mb-0">Fecha de redencion: {{new Date(redemption.redemptionDate).toLocaleDateString()}}</p>
    <p class="mb-0">ID Usuario: {{redemption.uid}}</p>
    <div class="flex justify-between items-center w-full mt-auto">
      <button class="py-1 px-4 bg-yellow text-white rounded-md" v-if="!isEditable" @click="isEditable = true">Actualizar estado</button>
      <button 
        v-else 
        class="py-1 px-4 bg-yellow text-white rounded-md"
        @click="save"
        :disabled="isLoading"
      >
        {{ isLoading ? 'Cargando...' : 'Guardar' }}
      </button>
      <router-link :to="`/user/${redemption.uid}`">Ver usuario</router-link>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
  export default {
    props: ["redemption"],
    data() {
      return {
        isEditable: false,
        newStatus: "",
        isLoading: false,
      }
    },
    methods: {
      ...mapActions(["redemptions/updateRedemptionPointsStatus"]),
      async save() {
        if (this.newStatus.length === 0) this.newStatus = this.redemption.status;
        this.isLoading = true;
        await this["redemptions/updateRedemptionPointsStatus"]({
          id: this.redemption.id,
          newStatus: this.newStatus
        });
        this.isLoading = false;
        this.isEditable = false;
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>