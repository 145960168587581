<template>
  <div class="bg-yellow flex flex-col items-start mt-8 w-11/12 self-center p-8">
    <h2 class="uppercase font-bold text-2xl">Redenciones por tramitar</h2>
    <div class="grid grid-cols-3 gap-4 w-full">
      <RedemptionPoints
        v-for="redemption in filteredRedemptions"
        :key="redemption.id"
        :redemption="redemption"
      />
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
import RedemptionPoints from '../../components/RedemptionPoints.vue';
  export default {
  components: { RedemptionPoints },
    mounted() {
      this["redemptions/getRedemptionsPoints"]();
    },
    computed: {
      ...mapState({
        redemptions: state => state.redemptions.redemptions
      }),
      filteredRedemptions() {
        return this.redemptions.filter((x) => x.status != "Entregado");
      },
    },
    methods: {
      ...mapActions(["redemptions/getRedemptionsPoints"]),
    },
  }
</script>

<style lang="scss" scoped>

</style>